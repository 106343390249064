import * as React from 'react';
import { Helmet } from 'react-helmet';

import Footer from '../components/Footer';
import Header from '../components/Header/Main';
// import CamaImage from '../images/cama.jpeg';
import '../style.css';

const Page = ({ pageContext }) => {
    return (
        <div className="bg-slate-200">
            <Helmet>
                <title>{pageContext.description}</title>
            </Helmet>
            <Header />
            <main>
                <div className="px-5">
                    <h1>{pageContext.Title}</h1>
                    <p>{pageContext.Description}</p>
                </div>
                <div className="divide-y divide-gray-300 text-sm">
                    <div className="py-2">
                        <a href="#">
                            <div className="relative">
                                <img
                                    // src={CamaImage}
                                    alt="A dog smiling in a party hat"
                                />
                                <div className="absolute left-0 top-0 bg-slate-700/70 mt-1 ml-1 px-2 py-1 rounded-sm text-white text-xs ">
                                    <span>New</span>
                                </div>
                            </div>
                            <div className="px-2">
                                <div>
                                    <div className="px-2 text-slate-900 text-xl">
                                        € 123.44
                                    </div>
                                </div>
                                <div className="inline-flex divide-x divide-gray-400 text-sm">
                                    <div className="px-2">2 bds</div>
                                    <div className="px-2">3 ba</div>
                                    <div className="px-2">123 m.</div>
                                    <div className="px-2">Apartment</div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="py-2">
                        <a href="#">
                            <div className="relative">
                                <img
                                    // src={CamaImage}
                                    alt="A dog smiling in a party hat"
                                />
                                <div className="absolute left-0 top-0 bg-slate-700/70 mt-1 ml-1 px-2 py-1 rounded-sm text-white text-xs ">
                                    <span>New</span>
                                </div>
                            </div>
                            <div className="px-2">
                                <div>
                                    <div className="px-2 text-slate-900 text-xl">
                                        € 123.44
                                    </div>
                                </div>
                                <div className="inline-flex divide-x divide-gray-400 text-sm">
                                    <div className="px-2">2 bds</div>
                                    <div className="px-2">3 ba</div>
                                    <div className="px-2">123 m.</div>
                                    <div className="px-2">Apartment</div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Page;
